import React from 'react';
import styled from 'styled-components';

import { Trans, useTranslation } from 'react-i18next';
import { device } from '../devices';

function Intro() {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Titles>
                <WhatIsUnitas>{t('What is Unitas?')}</WhatIsUnitas>
                <WhatIsUnitasExplain>
                    {t(
                        'Unitas Foundation enables financial sovereignty by granting people the right to choose their units of account while transacting with each other.'
                    )}
                </WhatIsUnitasExplain>
            </Titles>
            <UnitasContent>
                <UnitasContentP>
                    <Trans>
                        Unitas Protocol defines a new stablecoin category -- {' '}
                        <UnitasContentSpan>
                            unitized stablecoins
                        </UnitasContentSpan>
                        , which serve as units of account representing emerging
                        market currencies. Unitas’ unitized stablecoins are
                        over-reserved with exogenous USD stablecoins (e.g.,
                        USDT, USDC, Dai).
                    </Trans>
                </UnitasContentP>
                <UnitasContentP>
                    {t(
                        `Holding a Unitas stablecoin is essentially holding a USD stablecoin; for example, holding 1 Unitas Indian Rupee stablecoin is essentially holding 1/80 USDT (at the current RBI rate). Therefore, Unitas stablecoins are affixed with their corresponding country code, e.g., USD91 for Indian Rupee, USD971 for UAE Dirham, and USD1 for US Dollar.`
                    )}
                </UnitasContentP>
                <UnitasContentP>
                    {t(
                        `The goal is to “unitize” a USD stablecoin into one local currency unit, thereby providing transaction ease and efficiency for people in different countries. Unitas Protocol is a value translator between USD and other currencies and guarantees that each Unitas stablecoin can unconditionally convert “back” to a USD stablecoin.`
                    )}
                </UnitasContentP>
            </UnitasContent>
        </Wrapper>
    );
}

export default Intro;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 120px;
    @media ${device.tablet} {
        flex-direction: column;
    }
    @media ${device.laptop} {
        flex-direction: row;
        margin-bottom: 16.66vw;
    }
`;
const Titles = styled.div`
    @media ${device.laptop} {
        margin-right 5.4vw;
    }
`;
const Title = styled.h2`
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;

    @media ${device.tablet} {
    }
    @media ${device.laptop} {
        font-size: 2.2vw;
        line-height: 2.9vw;
    }
`;
const WhatIsUnitas = styled(Title)`
    margin-bottom: 27px;
    @media ${device.laptop} {
        margin-bottom: 42px;
    }
`;
const WhatIsUnitasExplain = styled(Title)`
    margin-bottom: 30px;
    @media ${device.laptop} {
        margin-bottom: 0px;
    }
`;
const UnitasContent = styled.div``;
const UnitasContentP = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    &:not(:last-child) {
        margin-bottom: 24px;
    }

    @media ${device.laptop} {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
        font-size: 1.38vw;
        line-height: 2.5vw;
    }
`;
const UnitasContentSpan = styled.span`
    font-style: italic;
`;
