import React from 'react';
import styled, { css } from 'styled-components';

import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '../common/Button';

// utils
import { device } from '../devices';
import { useRecoilValue } from 'recoil';
import { isMobileSelector } from '../recoils/device.selector';

interface LaunchAppButton extends Pick<ButtonProps, 'size'> {
    isTooltipFloating: boolean;
}

function LaunchAppButton(props: LaunchAppButton) {
    const { isTooltipFloating, size } = props;
    const { t } = useTranslation();

    const isMobile = useRecoilValue(isMobileSelector);
    return (
        <LaunchAppButtonWrapper
            href={'https://app.unitas.foundation/'}
            target={'_blank'}
            rel="noreferrer noopenner"
        >
            <FakeButton size={size} buttonType={'primary'} disabled={true}>
                {t('LAUNCH APP')}
            </FakeButton>
        </LaunchAppButtonWrapper>
    );
}

export default LaunchAppButton;

const LaunchAppButtonWrapper = styled.a`
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1;
    @media ${device.tablet} {
        flex-grow: 0;
    }
`;
const FakeButton = styled.div<ButtonProps>`
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    padding: 20px 16px 20px 16px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-grow: 1;
    ${(props) =>
        props.buttonType === 'primary'
            ? css`
                  background: #000000;
                  color: #ffffff;
              `
            : ''};
    &:hover {
    }
    &:active {
    }
    @media ${device.tablet} {
        width: ${(props) => (props.size === 'Large' ? '190' : '110')}px;
        flex-grow: 0;
        ${(props) =>
            props.size === 'Large'
                ? css`
                      font-size: 16px;
                      padding: 32px;
                      border-radius: 46px;
                      font-weight: 400;
                  `
                : css`
                      font-size: 12px;
                      padding: 16px;
                      border-radius: 26px;
                      font-weight: 400;
                  `}
    }
    @media ${device.laptop} {
        width: ${(props) => (props.size === 'Large' ? '13.2' : '7.6')}vw;
        flex-grow: 0;
        ${(props) =>
            props.size === 'Large'
                ? css`
                      font-size: 1.1vw;
                      padding: 2.4vw;
                      border-radius: 3.19vw;
                      font-weight: 400;
                  `
                : css`
                      font-size: 0.83vw;
                      padding: 1.1vw;
                      border-radius: 1.8vw;
                      font-weight: 400;
                  `}
    }
`;
