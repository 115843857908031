import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { device } from '../devices';

// assets
import usdt from '../assets/icons/usdt.png';
import usdc from '../assets/icons/usdc.png';
import dai from '../assets/icons/dai.png';
import busd from '../assets/icons/busd.png';

function AssetsExplain() {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Coins>
                <CoinImage src={usdt} />
                <CoinImage src={usdc} />
                <CoinImage src={dai} />
                <CoinImage src={busd} />
            </Coins>
            <TitleWrapper>
                <Title>
                    {t(
                        'Unitas stablecoins are 130% to 200% over-reserved with USD stablecoins such as USDT, USDC, Dai, BUSD, etc.'
                    )}
                </Title>
                <SubTitle>
                    {t(
                        'In the unlikely event that the over-reserve ratio decreases to 100%, Unitas Protocol executes a global settlement that auto-converts all Unitas stablecoins to USD stablecoins.'
                    )}
                </SubTitle>
            </TitleWrapper>
            <Content>
                <Desc>
                    {t(
                        'To acquire Unitas stablecoins such as USD91, a user first acquires the Unitas USD stablecoin USD1. She’d swap her USD stablecoin (e.g., USDT) with the protocol for USD1. The protocol takes her USD stablecoin and mints out USD1 for her in exchange.'
                    )}
                </Desc>
                <Desc>
                    {t(
                        'She’d proceed to swap her USD1 for USD91 with the protocol; Unitas burns her USD1 and mints out USD91 for her in exchange. Unlike Maker and Liquidty, Unitas users aren’t responsible for the protocol’s over-reservation.'
                    )}
                </Desc>
                <Desc>
                    {t(
                        'Insurance Providers (IPs) lend their USD stablecoins to the protocol to fund its over-reservation. We call this “IP staking.” During a lending period, the protocol mints out 4REX to an IP, allowing the IP to share the protocol’s profits. The protocol also creates a Collateralized Debt Position (CDP) for this loan so that once the loan matures, the IP can redeem her principal.'
                    )}
                </Desc>
                <Desc>
                    {t(
                        'When a loan matures, and the IP decides to use the CDP to redeem her principal, she will need to return to the protocol the exact amount of 4REX tokens she received when creating this CDP.'
                    )}
                </Desc>
            </Content>
        </Wrapper>
    );
}

export default AssetsExplain;
const Wrapper = styled.div`
    display: flex;
    margin-bottom: 120px;
    flex-direction: column;

    @media ${device.laptop} {
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 16.66vw;
    }
`;

const Coins = styled.div`
    flex-basis: 100%;
    flex-shrink: 0;
    margin-bottom: 30px;
    @media ${device.laptop} {
        margin-bottom: 80px;
    }
`;

const CoinImage = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    &:not(:last-child) {
        margin-right: 16px;
    }
    @media ${device.laptop} {
        width: 3.61vw;
        height: 3.61vw;
    }
`;

const TitleWrapper = styled.div`
    margin-bottom 30px;
    flex: 1;
    @media ${device.laptop} {
        margin-bottom 0px;
        margin-right: 78px;
    }
`;

const Content = styled.div`
    @media ${device.laptop} {
        flex: 1;
    }
`;

const Title = styled.h2`
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    @media ${device.laptop} {
        margin-bottom: 40px;
        font-weight: 700;
        font-size: 2.22vw;
        line-height: 2.91vw;
    }
`;
const SubTitle = styled.h4`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    @media ${device.laptop} {
        font-weight: 700;
        font-size: 1.3vw;
        line-height: 1.66vw;
    }
`;

const Desc = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    &:not(:last-child) {
        margin-bottom: 24px;
    }

    @media ${device.laptop} {
        font-weight: 400;
        font-size: 1.38vw;
        line-height: 2.5vw;
        &:not(:last-child) {
            margin-bottom: 36px;
        }
    }
`;
