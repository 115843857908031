import axios from 'axios';
import { RequestParams } from '../types/data.type';

// Types

declare module 'axios' {
    interface AxiosRequestConfig {
        /**
         * Replace path params. For example:
         *
         * ```ts
         * axios.get('https://user-service.works/:userId, { pathParams: { userId: 'abc123' } });
         * ```
         *
         * will replace `:userId` with `abc123`
         */
        pathParams?: RequestParams;
    }
}

axios.interceptors.request.use((config) => {
    const { url, pathParams } = config;

    if (!(url && pathParams)) return config;

    let newUrl = url;
    Object.entries(pathParams).forEach(([key, value]) => {
        newUrl = newUrl.replace(`:${key}`, `${value}`);
    });
    return {
        ...config,
        url: newUrl,
    };
});