import React, { useEffect } from 'react';

/**
 * Hook that track touch evnet outside of the elements
 */
export function useMouseDownOutside<T>(
    ref: React.RefObject<T>,
    callback: () => void,
    dep?: any
) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleMouseDownOutside(event: MouseEvent) {
            // TODO: remove any
            if (ref.current && !(ref.current as any).contains(event.target)) {
                typeof callback === 'function' && callback();
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleMouseDownOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleMouseDownOutside);
        };
    }, [ref, dep]);
}
