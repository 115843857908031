import { selector } from 'recoil';
import { size } from '../devices/size';
import { windowDimensionsAtom } from './windowDimensions.atom';

export const isMobileSelector = selector({
    key: 'isMobileSelector',
    get: ({ get }) => {
        const windowDimensions = get(windowDimensionsAtom);

        return (
            !!windowDimensions?.width && windowDimensions.width < size.tablet
        );
    },
});

export const isTabletSelector = selector({
    key: 'isTabletSelector',
    get: ({ get }) => {
        const windowDimensions = get(windowDimensionsAtom);

        return (
            !!windowDimensions?.width && windowDimensions.width >= size.tablet && windowDimensions.width < size.laptop
        );
    },
});

export const isDesktopSelector = selector({
    key: 'isDesktopSelector',
    get: ({ get }) => {
        const windowDimensions = get(windowDimensionsAtom);

        return (
            !!windowDimensions?.width && windowDimensions.width >= size.laptop
        );
    },
});

