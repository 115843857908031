import React from 'react';
import styled, { keyframes } from 'styled-components';

import { useTranslation } from 'react-i18next';
import { device } from '../devices';

// assets
import telegram from '../assets/images/telegram.png';
import twitter from '../assets/images/twitter.png';

function Marquee() {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <MarqueeWrapper>
                <HeadeLineWrap>
                    <HeadeLine>
                        {t(
                            'Let’s unleash emerging market potentials by using decentralized blockchain protocols!'
                        )}
                    </HeadeLine>
                </HeadeLineWrap>
            </MarqueeWrapper>
            <Actions>
                <Action
                    href={'https://resources.unitas.foundation/telegram'}
                    target={'_blank'}
                    rel="noreferrer noopenner"
                >
                    <ActionIcon src={telegram} />
                    <ActionText>{t('JOIN US ON TELEGRAM')}</ActionText>
                </Action>
                <Action
                    href={'https://twitter.com/UnitasProtocol'}
                    target={'_blank'}
                    rel="noreferrer noopenner"
                >
                    <ActionIcon src={twitter} />
                    <ActionText>{t('FOLLOW US ON TWITTER')}</ActionText>
                </Action>
            </Actions>
        </Wrapper>
    );
}

export default Marquee;

const Wrapper = styled.div`
    margin-bottom: 120px;
    @media ${device.laptop} {
        margin-bottom: 16.66vw;
    }
`;
const MarqueeWrapKeyFrame = keyframes`
    0% {
        transform: translateX(100%);
    }
    100% { 
        transform: translateX(0);
    }
`;
const MarqueeKeyFrame = keyframes`
    0% {
        transform: translateX(0%);
    }
    
    100% { 
        transform: translateX(-100%);
    }
`;

const MarqueeWrapper = styled.div`
    position: relative;
    overflow: hidden;
    border: 1px solid #000000;
    border-left-width: 0;
    border-right-width: 0;
    display: flex;
    align-items: center;
    height: 58px;
    width: 100%;
    margin-bottom: 30px;
    @media ${device.laptop} {
        margin-bottom: 2.08vw;
        height: 5.13vw;
    }
`;
const HeadeLineWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    animation: ${MarqueeWrapKeyFrame} 10s linear infinite;
`;
const HeadeLine = styled.h4`
    color: #000000;
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    white-space: nowrap;
    animation: ${MarqueeKeyFrame} 10s linear infinite;
    @media ${device.laptop} {
        font-weight: 700;
        font-size: 2.22vw;
        line-height: 2.91vw;
    }
`;
const Actions = styled.div`
    display: flex;
    flex-direction: column;
    @media ${device.laptop} {
        flex-direction: row;
    }
`;
const Action = styled.a`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @media ${device.laptop} {
        margin-right: 4.16vw;
    }
`;
const ActionIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 8px;
    @media ${device.laptop} {
        width: 1.66vw;
        height: 1.66vw;
        margin-right: 1.11vw;
    }
`;
const ActionText = styled.p`
    color: #000000;
    flex-shrink: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    @media ${device.laptop} {
        font-weight: 700;
        font-size: 1.38vw;
        line-height: 1.66vw;
    }
`;
