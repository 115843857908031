import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { device } from '../devices';

// assets
import northEast from '../assets/images/north_east.png';


function WhyUnitas() {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Title>{t('Why use Unitas?')}</Title>
            <Content>
                <Desc>
                    {t(
                        `Unitas levels the playing field for emerging market businesses and entrepreneurs by elevating their financial sovereignty and integrating them into the global financial and DeFi systems.`
                    )}
                </Desc>
                <Desc>
                    {t(
                        `By creating the new stablecoin category “unitized stablecoins,” Unitas Protocol operates exogenously over-reserved stablecoins pegged to emerging market currencies. These stablecoins unleash emerging market potentials by facilitating foreign investment, cross-border payment, global market access, DeFi participation, efficient USD liquidity, and more.`
                    )}
                </Desc>
            </Content>
            <Links>
                <Link
                    href={'https://resources.unitas.foundation/whitepapers'}
                    target={'_blank'}
                    rel="noreferrer noopenner"
                >
                    {t('WHITEPAPERS')}
                    <NorthEastImg src={northEast} />
                </Link>
                <Link
                    href={'mailto:team@unitas.foundation'}
                    target={'_blank'}
                    rel="noreferrer noopenner"
                >
                    {t('CONTACT US')}
                </Link>
            </Links>
        </Wrapper>
    );
}

export default WhyUnitas;
const Wrapper = styled.div`
    display: flex;
    margin-bottom: 120px;
    flex-direction: column;
    @media ${device.laptop} {
        width: 40.8vw;
        margin: 0 auto;
        margin-bottom: 275px;
    }
`;

const Content = styled.div`
    margin-bottom: 30px;
    @media ${device.laptop} {
        margin-bottom: 40px;
        flex: 1;
    }
`;

const Title = styled.h2`
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    width: 204px;
    @media ${device.laptop} {
        width: 40.8vw;
        margin-bottom: 60px;
        font-weight: 700;
        font-size: 5.55vw;
        line-height: 6.73vw;
    }
`;

const Desc = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    &:not(:last-child) {
        margin-bottom: 24px;
    }

    @media ${device.laptop} {
        font-weight: 400;
        font-size: 1.38vw;
        line-height: 2.5vw;
        &:not(:last-child) {
            margin-bottom: 36px;
        }
    }
`;
const Links = styled.div`
    display: flex;
    @media ${device.laptop} {
        justify-content: flex-end;
    }
`;

const Link = styled.a`
    position: relative;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-right: 16px;
    }
    @media ${device.laptop} {
        font-weight: 700;
        font-size: 1.38vw;
        &:not(:last-child) {
            margin-right: 48px;
        }
    }
`;
const NorthEastImg = styled.img`
    width: 16px;
    height: 16px;
    margin-left: 8px;
    @media ${device.laptop} {
        width: 1.1vw;
        height: 1.1vw;
        margin-left: 0.55vw;
    }
`;
