import React from 'react';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { windowDimensionsAtom } from '../recoils';
import { getWindowDimensions } from '../recoils/windowDimensions.atom';

function WindowDimensions() {
    const setWindowDimensions = useSetRecoilState(windowDimensionsAtom);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [setWindowDimensions]);
    return <></>;
}

export default WindowDimensions;
