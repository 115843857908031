import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import { ResetStyle } from './common/ResetCss';
import { RecoilRoot } from 'recoil';

// Init interceptors
import './interceptors';

// components
import Header from './components/Header';
import Jumbo from './components/Jumbo';
import HorizontalPadder from './common/HorizontalPadder';
import WindowDimensions from './common/WindowDimensions';
import Intro from './components/Intro';
import HowItWorks from './components/HowItWorks';
import AssetsExplain from './components/AssetsExplain';
import WhyUnitas from './components/WhyUnitas';
import WhatTheMeaning from './components/WhatTheMeaning';
import Teams from './components/Teams';
import Footer from './components/Footer';
import Marquee from './components/Marquee';

import ReactGA from 'react-ga4';
import JoinTheMove from './components/JoinTheMove';

function App() {
    const queryClient = new QueryClient();
    useEffect(() => {
        ReactGA.initialize('G-NFD03PJFV9');
    }, []);

    return (
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <WindowDimensions />
                    <ResetStyle />
                    <Header />
                    <Jumbo />
                    <HorizontalPadder>
                        <Intro />
                    </HorizontalPadder>
                    <HowItWorks />
                    <HorizontalPadder>
                        <>
                            <AssetsExplain />
                            <Marquee />
                            <WhyUnitas />
                            <WhatTheMeaning />
                            <Teams />
                            <JoinTheMove/>
                        </>
                    </HorizontalPadder>
                    <Footer />
                </ThemeProvider>
            </QueryClientProvider>
        </RecoilRoot>
    );
}
export default App;
