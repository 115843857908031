import React from 'react';
import { atom } from 'recoil';
export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};
export const windowDimensionsAtom = atom<{
    width: number;
    height: number;
} | null>({
    key: 'windowDimensionsAtom',
    default: getWindowDimensions(),
});
