import React from 'react';
import styled from 'styled-components';

import { Trans, useTranslation } from 'react-i18next';
import { device } from '../devices';

function WhatTheMeaning() {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Title>{t('What’s the meaning behind the name Unitas?')}</Title>
            <Content>
                <Desc>
                    <Trans>
                        “Unitas” was the codename for{' '}
                        <Link
                            target={'_blank'}
                            rel="noreferrer noopenner"
                            href={
                                'https://resources.unitas.foundation/unitas-hegemony-war-bretton-woods'
                            }
                        >
                            Harry White’s proposal
                        </Link>{' '}
                        at the 1944 Bretton Woods conference, which gave birth
                        to today’s International Monetary Fund (IMF). White was
                        the economist representing the US. Parts of our Unitas
                        Protocol’s design resemble White’s “Unitas,” since our
                        goal is to create a DeFi “translator” that can
                        unconditionally convert an emerging market stablecoin
                        back to a USD stablecoin.
                    </Trans>
                </Desc>
            </Content>
        </Wrapper>
    );
}

export default WhatTheMeaning;
const Wrapper = styled.div`
    display: flex;
    margin-bottom: 120px;
    flex-direction: column;
    @media ${device.laptop} {
        flex-direction: row;
        margin: 0 auto;
        margin-bottom: 16.6vw;
    }
`;

const Content = styled.div`
    margin-bottom: 30px;
    @media ${device.laptop} {
        margin-bottom: 40px;
        flex: 1;
    }
`;

const Title = styled.h2`
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;

    @media ${device.laptop} {
        width: 42%;
        margin-right: 5.4vw;
        margin-bottom: 60px;
        font-weight: 700;
        font-size: 2.7vw;
        line-height: 3.33vw;
    }
`;

const Desc = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    &:not(:last-child) {
        margin-bottom: 24px;
    }

    @media ${device.laptop} {
        font-weight: 400;
        font-size: 1.38vw;
        line-height: 2.5vw;
        &:not(:last-child) {
            margin-bottom: 36px;
        }
    }
`;
const Link = styled.a`
    cursor: pointer;
    text-decoration: underline;
`;
