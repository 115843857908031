import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LaunchAppButton from './LaunchAppButton';

// assets
import triangle from '../assets/images/triangle.png';
import ellipse from '../assets/images/ellipse.png';
import people from '../assets/images/people.png';
import earth from '../assets/images/earth.png';
import northEast from '../assets/images/north_east.png';
import dune from '../assets/images/dune.png';

// constants
import { MARGIN, MOBILE_PADDING, TABLET_MARGIN } from '../constants';

// utils
import { device } from '../devices';

function Jumbo() {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container>
                <Content>
                    <Slogan>
                        <Triangle src={triangle} />

                        <Ellipse src={ellipse} />

                        <NewSection>
                            <TitleNew>{t('New')}</TitleNew>
                            <Arrow
                                width="172"
                                height="auto"
                                viewBox="0 0 172 12"
                                preserveAspectRatio="xMidYMin slice"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M166.576 6.5306L163.883 7.24601C160.81 8.06241 158.011 9.02118 155.549 10.1006L154.304 10.6467L155.356 11L156.601 10.4539C158.987 9.40774 161.7 8.47847 164.679 7.68719L170 6.27373L164.681 4.7038C161.72 3.82988 159.061 2.81501 156.768 1.68361L155.382 0.999993L154.278 1.32943L155.664 2.01304C158.03 3.18036 160.773 4.22745 163.828 5.12912L166.759 5.99401L1 5.99402L1 6.53061L166.576 6.5306Z"
                                    fill="black"
                                    stroke="black"
                                />
                            </Arrow>
                        </NewSection>
                        <Break></Break>
                        <TitleCurrency>{t('Currency')}</TitleCurrency>
                        <TitleRevolutionSection>
                            <TitleRevolution>{t('Revolution')}</TitleRevolution>
                            <ImgSection>
                                <PeopleImg src={people} />
                                <EarthImg src={earth} />
                            </ImgSection>
                        </TitleRevolutionSection>
                        <DuneLinkWrapper>
                            <DuneLink
                                href={
                                    'https://resources.unitas.foundation/unitas-dashboard'
                                }
                                target={'_blank'}
                                rel="noreferrer noopenner"
                            >
                                <DuneImg src={dune} />
                                Dune
                                <NorthEaseImg src={northEast} />
                            </DuneLink>
                        </DuneLinkWrapper>
                    </Slogan>

                    <Actions>
                        <WhitePaperLink
                            href={
                                'https://resources.unitas.foundation/whitepapers'
                            }
                            target={'_blank'}
                            rel="noreferrer noopenner"
                        >
                            {t('WHITEPAPERS')}
                            <NorthEaseImg src={northEast} />
                        </WhitePaperLink>
                        <LaunchAppButton
                            size="Large"
                            isTooltipFloating={true}
                        />
                    </Actions>
                </Content>
            </Container>
        </Wrapper>
    );
}

export default Jumbo;

const AnimationTime = 0.4;
const initDelay = 0.6;
const Wrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: ${window.innerHeight ? `${window.innerHeight}px` : '100vh'};

    margin-top: -60px;
    margin-bottom: 120px;
    @media ${device.tablet} {
        height: 100vh;
        margin-bottom: 0;
    }
    @media ${device.laptop} {
        height: auto;
        margin-top: 6.25vw;
        margin-bottom: 120px;
    }
`;
const Container = styled.div`
    height: calc(100% - 60px);
    width: 100%;
    margin: 0 ${MOBILE_PADDING}px;
    @media ${device.tablet} {
        margin: 0 ${TABLET_MARGIN}px;
    }
    @media ${device.laptop} {
        margin: 0 ${MARGIN}px;
        padding: 0 ${MARGIN}px;
        box-sizing: border-box;
        height: calc(100% - 100px);
    }
`;
const Content = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    @media ${device.tablet} {
        justify-content: center;
    }
`;
const Slogan = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 170px;
    @media ${device.tablet} {
        margin-bottom: 0px;
    }
`;

const DuneLinkWrapper = styled.div`
    order: 7;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    @media ${device.tablet} {
        margin-left: ${TABLET_MARGIN}px;
    }
`;
const DuneLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    font-size: 12px;
    @media ${device.laptop} {
        font-size: 1.11vw;
    }
`;
const DuneImg = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 4px;
    @media ${device.tablet} {
        width: 1.38vw;
        height: 1.38vw;
        margin-right: 0.27vw;
    }
`;

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    @media ${device.tablet} {
        justify-content: flex-end;
    }
`;
const Ellipse = styled.img`
    @keyframes fadeAndRotate {
        from {
            opacity: 0;
            transform: translateY(20px) rotate(15deg);
        }
        to {
            opacity: 1;
            transform: translateY(0) rotate(15deg);
        }
    }
    animation: ${AnimationTime}s ease-in-out ${initDelay + 0.4}s both
        fadeAndRotate;
    order: 1;
    width: 122px;
    height: 78px;
    @media ${device.tablet} {
        order: 4;
        width: 143px;
        height: 86px;
        margin-right: 10px;
    }
    @media ${device.laptop} {
        order: 4;
        height: auto;
        width: 16vw;
        margin-right: 26px;
        margin-bottom: 28px;
        margin-left: max(-2vw, -25px);
    }
    @media ${device.laptopL} {
        margin-left: -25px;
        margin-right: 26px;
    }
`;
const Triangle = styled.img`
    animation: ${AnimationTime}s ease-in-out ${initDelay}s both fadeTitle;
    order: 0;
    width: auto;
    height: 13vw;
    margin-right: 44px;
    @media ${device.tablet} {
        width: auto;
        height: min(8vw, 103px);
        margin-left: ${TABLET_MARGIN}px;
        margin-right: 16px;
    }
    @media ${device.laptop} {
        margin-right: 16px;
        margin-bottom: 28px;
        height: 8vw;
        width: auto;
        margin-right: 26px;
    }
`;
const NewSection = styled.div`
    animation: ${AnimationTime}s ease-in-out ${initDelay}s both fadeTitle;
    order: 2;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: -5px;
    @media ${device.tablet} {
        margin-bottom: 0px;
        margin-top: 0px;
        order: 1;
        width: auto;
        justify-content: flex-start;
        flex-grow: 1;
        margin-right: ${TABLET_MARGIN}px;
    }
    @media ${device.laptop} {
        margin-bottom: 28px;
        margin-right: 0;
        order: 1;
    }
`;
const Title = styled.h1`
    font-weight: 800;
    font-size: 16.6vw;
    line-height: 20vw;
    @media ${device.tablet} {
        font-size: 12vw;
        line-height: 13.55vw;
    }
    @media ${device.laptop} {
        font-size: 11vw;
        line-height: 11vw;
    }
    @media ${device.laptopL} {
        font-size: 11vw;
        line-height: 11vw;
    }
`;
const TitleNew = styled(Title)`
    @keyframes fadeTitle {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    margin-right: 12px;
    @media ${device.tablet} {
        margin-right: 20px;
    }
`;
const TitleCurrency = styled(Title)`
    animation: ${AnimationTime}s ease-in-out ${initDelay + 0.1}s both fadeTitle;
    order: 5;
    flex: 1;
    text-align: right;
    margin-top: -12px;
    @media ${device.tablet} {
        margin-top: 0px;
        text-align: left;
        flex: 1;
    }
    @media ${device.laptop} {
        margin-bottom: 28px;
        text-align: left;
        flex: 1;
    }
`;
const TitleRevolutionSection = styled.div`
    margin-top: -12px;
    margin-bottom: 20px;
    order: 6;
    text-align: left;
    position: relative;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    @media ${device.tablet} {
        margin-top: 0px;
        margin-bottom: 40px;
        margin-left: ${TABLET_MARGIN}px;
    }
    @media ${device.laptop} {
        margin-bottom: 60px;
    }
`;
const TitleRevolution = styled(Title)`
    animation: ${AnimationTime}s ease-in-out ${initDelay + 0.2}s both fadeTitle;
`;
const Arrow = styled.svg`
    flex-shrink: 1;
    flex-grow: 1;
`;
const Break = styled.div`
    @media ${device.tablet} {
        order: 3;
        flex-basis: 100%;
        height: 1px;
    }
`;

const ImgSection = styled.div`
    animation: ${AnimationTime}s ease-in-out ${initDelay + 0.4}s both fadeTitle;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -120px;
    margin: 0 auto;
    width: fit-content;
    @media ${device.tablet} {
        position: relative;
        margin-left: 4vw;
        bottom: 0px;
    }
`;

const PeopleImg = styled.img`
    position: relative;
    left: 0;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    @media ${device.tablet} {
        width: 90px;
        height: 90px;
    }
    @media ${device.laptop} {
        width: 8.19vw;
        height: 8.19vw;
    }
`;

const EarthImg = styled.img`
    position: absolute;
    left: 42px;
    bottom: 22px;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    @media ${device.tablet} {
        width: 90px;
        height: 90px;
    }
    @media ${device.laptop} {
        width: 8.19vw;
        height: 8.19vw;
        left: 60%;
        bottom: 33.89%;
    }
`;

const WhitePaperLink = styled.a`
    position: relative;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    padding: 20px 0 20px 0;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #000000;
    color: #000000;
    flex: 1;
    margin-right: 16px;
    &:hover {
    }
    &:active {
    }
    @media ${device.tablet} {
        font-size: 16px;
        padding: 32px;
        border-radius: 46px;
        font-weight: 400;
        flex-grow: 0;
        margin-right: 32px;
        width: 190px;
    }
    @media ${device.laptop} {
        font-size: 1.1vw;
        padding: 2.4vw;
        border-radius: 3.19vw;
        font-weight: 400;
        flex-grow: 0;
        margin-right: 32px;
        width: 13.2vw;
    }
`;
const NorthEaseImg = styled.img`
    width: 16px;
    height: 16px;
    margin-left: 8px;
    @media ${device.laptop} {
        width: 1.1vw;
        height: 1.1vw;
        margin-left: 0.55vw;
    }
`;
