import React from 'react';
import styled, { keyframes } from 'styled-components';

import { useTranslation } from 'react-i18next';
import { device } from '../devices';

// assets
import telegram from '../assets/images/telegram.png';
import twitter from '../assets/images/twitter.png';

function JoinTheMove() {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <HeadLine>{t('Join the movement!')}</HeadLine>
            <Actions>
                <Action
                    href={'https://resources.unitas.foundation/telegram'}
                    target={'_blank'}
                    rel="noreferrer noopenner"
                >
                    <ActionIcon src={telegram} />
                    <ActionText>{t('JOIN US ON TELEGRAM')}</ActionText>
                </Action>
                <Action
                    href={'https://twitter.com/UnitasProtocol'}
                    target={'_blank'}
                    rel="noreferrer noopenner"
                >
                    <ActionIcon src={twitter} />
                    <ActionText>{t('FOLLOW US ON TWITTER')}</ActionText>
                </Action>
            </Actions>
            <MoveText>
                {t(
                    'Unitas Foundation is actively seeking members, collaborators, and partners including for example:'
                )}
            </MoveText>
            <Partners>
                <Partner>
                    <PartnerTitle>{t('Financial institutions')}</PartnerTitle>
                    <PartnerList>
                        {[
                            'Banks',
                            'Payment organizations',
                            'Money transfer operators',
                            'Money service organizations',
                            'Payment organizations',
                            'Digital wallets and credit card issuers',
                        ].map((item, index) => (
                            <PartnerListItem key={index}>
                                {t(item)}
                            </PartnerListItem>
                        ))}
                    </PartnerList>
                </Partner>
                <Partner>
                    <PartnerTitle>{t('VASPs')}</PartnerTitle>
                    <PartnerList>
                        {[
                            'Centralized exchange',
                            'Decentralized exchange',
                            'Stablecoin issuers',
                            'GameFi',
                            'Market makers',
                            'OTC desks',
                        ].map((item, index) => (
                            <PartnerListItem key={index}>
                                {t(item)}
                            </PartnerListItem>
                        ))}
                    </PartnerList>
                </Partner>
            </Partners>
            <Partners>
                <Partner>
                    <PartnerTitle>{t('Regulators')}</PartnerTitle>
                    <PartnerList>
                        {[
                            'Central banks',
                            'Government departments',
                            'Law enforcement entities',
                        ].map((item, index) => (
                            <PartnerListItem key={index}>
                                {t(item)}
                            </PartnerListItem>
                        ))}
                    </PartnerList>
                </Partner>
                <Partner>
                    <PartnerTitle>{t('Blockchain enthusiasts')}</PartnerTitle>
                    <PartnerList>
                        {[
                            'DeFi experts',
                            'Stablecoin passionate supporters',
                            'Financial inclusion proponents',
                        ].map((item, index) => (
                            <PartnerListItem key={index}>
                                {t(item)}
                            </PartnerListItem>
                        ))}
                    </PartnerList>
                </Partner>
            </Partners>
        </Wrapper>
    );
}

export default JoinTheMove;

const Wrapper = styled.div`
    margin-bottom: 40px;
    width: 100%;
    @media ${device.laptop} {
        margin-bottom: 16.66vw;
    }
`;

const HeadLine = styled.h2`
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    margin-bottom: 40px;
    @media ${device.laptop} {
        font-weight: 700;
        font-size: 5.5vw;
        line-height: 6.7vw;
        margin-bottom: 2vw;
    }
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    @media ${device.laptop} {
        margin-bottom: 3.75vw;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

const Action = styled.a`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @media ${device.laptop} {
        margin-bottom: 0;
        &:not(:last-child) {
            margin-right: 4.16vw;
        }
    }
`;
const ActionIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 8px;
    @media ${device.laptop} {
        width: 1.66vw;
        height: 1.66vw;
        margin-right: 1.11vw;
    }
`;
const ActionText = styled.p`
    color: #000000;
    flex-shrink: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    @media ${device.laptop} {
        font-weight: 700;
        font-size: 1.38vw;
        line-height: 1.66vw;
    }
`;
const MoveText = styled.p`
    color: #000000;
    flex-shrink: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    width: 320px;
    margin: 0 auto;
    margin-bottom: 120px;
    @media ${device.laptop} {
        text-align: center;
        font-weight: 700;
        font-size: 1.38vw;
        line-height: 1.66vw;
        display: block;
        width: 40vw;
        margin: 0 auto;
        margin-bottom: 5.55vw;
    }
`;
const Partners = styled.div`
    display: flex;
    flex-direction: column;
    @media ${device.laptop} {
        flex-direction: row;
    }
`;
const Partner = styled.div`
    flex: 1;
    @media ${device.laptop} {
        &:nth-child(2n + 1) {
            margin-right: 4.44vw;
        }
    }
`;
const PartnerTitle = styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    text-align: center;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #878787;
    @media ${device.laptop} {
        font-weight: 700;
        font-size: 2.22vw;
        line-height: 2.91vw;
        padding-bottom: 1.94vw;
        margin-bottom: 1.94vw;
    }
`;
const PartnerList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    @media ${device.laptop} {
        margin-bottom: 7.22vw;
    }
`;
const PartnerListItem = styled.p`
    font-weight: 400;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    @media ${device.laptop} {
        font-size: 1.38vw;
        line-height: 2.77vw;
    }
`;
