import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { device } from '../devices';

// assets
import life from '../assets/images/life.png';
import city from '../assets/images/city.png';
import lifeMobile from '../assets/images/life_mobile.png';
import cityMobile from '../assets/images/city_mobile.png';

import { MARGIN, MOBILE_PADDING, TABLET_MARGIN } from '../constants';
import { useRecoilValue } from 'recoil';
import { isDesktopSelector } from '../recoils/device.selector';

function HowItWorks() {
    const { t } = useTranslation();
    const isDesktop = useRecoilValue(isDesktopSelector);

    return (
        <Wrapper>
            {!isDesktop && <Title>{t('How does Unitas work?')}</Title>}
            <ContentContainer>
                <Image1 src={isDesktop ? life : lifeMobile} />
                {isDesktop && <Title>{t('How does Unitas work?')}</Title>}
                <Content>
                    <SubTitle>
                        {t(
                            'USD1 is Unitas’ base stablecoin and reflects the value of 1 exogenous USD stablecoin (e.g., USDT, USDC, Dai).'
                        )}
                    </SubTitle>

                    <Desc>
                        {t(
                            'One USD91 represents an amount of USD stablecoin whose value is equivalent to one INR (Indian Rupee). For example, if a USD stablecoin’s exchange rate against INR is 1:80, then one USD91 represents 1/80 of that USD stablecoin.'
                        )}
                    </Desc>
                    <Desc>
                        {t(
                            'Unitas Protocol implements different modes to obtain real-world rates via oracles.'
                        )}
                    </Desc>
                </Content>
                <Image2 src={isDesktop ? city : cityMobile} />
            </ContentContainer>
        </Wrapper>
    );
}

export default HowItWorks;
const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 120px;
    padding: 0 ${MOBILE_PADDING}px;
    @media ${device.tablet} {
        padding: 0 ${TABLET_MARGIN}px;
    }
    @media ${device.laptop} {
        padding: 0;
        margin-top: 18vw;
        margin-bottom: 16.66vw;
    }
`;

const Title = styled.h2`
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 22px;
    @media ${device.laptop} {
        position: absolute;
        width: 40.8vw;
        font-weight: 700;
        font-size: 5.5vw;
        line-height: 6.7vw;
        margin-bottom: 6.7vw;
        margin-top: -18vw;
        margin-left: ${MARGIN}px;
    }
`;

const ContentContainer = styled.div`
    @media ${device.laptop} {
        display: flex;
        justify-content: space-between;
    }
`;

const Content = styled.div`
    margin-bottom: 30px;
    @media ${device.laptop} {
        margin-bottom: 0;
        margin-right: 5.55vw;
    }
`;
const SubTitle = styled.h4`
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    height: 148px;
    display: flex;
    align-items: center;

    @media ${device.laptop} {
        height: auto;
        width: 40.8vw;
        font-weight: 700;
        font-size: 2.7vw;
        line-height: 3.33vw;
        margin-bottom: 60px;
        display: block;
    }
`;
const Desc = styled.p`
    clear: both;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &:not(:last-child) {
        margin-bottom: 24px;
    }
    @media ${device.laptop} {
        width: 40.8vw;
        font-size: 1.38vw;
        line-height: 2.5vw;
        &:not(:last-child) {
            margin-bottom: 36px;
        }
        clear: none;
    }
`;

const Image1 = styled.img`
    width: 90px;
    height: 148px;
    float: left;
    margin-bottom: 24px;
    margin-right: 20px;
    position: relative;
    margin-left: -${MOBILE_PADDING}px;
    border-radius: 0px 16px 16px 0px;
    @media ${device.tablet} {
        margin-left: -${TABLET_MARGIN}px;
    }
    @media ${device.laptop} {
        width: 17vw;
        height: fit-content;
        float: none;
        margin-left: 0;
        margin-right: 5.55vw;
        margin-top: 60px;
        margin-bottom: 0;
    }
`;

const Image2 = styled.img`
    width: 100%;
    height: auto;
    position: relative;

    left: 50%;
    transform: translateX(-50%);
    border-radius: 16px;
    @media ${device.laptop} {
        width: 31.25vw;
        max-width: 450px;
        height: fit-content;
        left: 0;
        transform: none;
        margin-top: -120px;
        border-radius: 32px 0 0 32px;
    }
`;
