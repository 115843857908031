import React from 'react';
import styled from 'styled-components';

// assets
import mail from '../assets/images/mail.png';
import twitter from '../assets/images/twitter.png';
import telegram from '../assets/images/telegram.png';
import medium from '../assets/images/medium.png';

// utils
import { device } from '../devices';
import { MARGIN, MOBILE_PADDING, TABLET_MARGIN } from '../constants';

function Footer() {
    return (
        <FooterWrapper>
            <Description>© 2023 Unitas</Description>

            <SocialMedia>
                <SocialMediaLink
                    href={'mailto:team@unitas.foundation'}
                    target={'_blank'}
                    rel="noreferrer noopenner"
                >
                    <SocialMediaIcon src={mail} />
                </SocialMediaLink>
                <SocialMediaLink
                    href={'https://twitter.com/UnitasProtocol'}
                    target={'_blank'}
                    rel="noreferrer noopenner"
                >
                    <SocialMediaIcon src={twitter} />
                </SocialMediaLink>
                <SocialMediaLink
                    href={'https://resources.unitas.foundation/telegram'}
                    target={'_blank'}
                    rel="noreferrer noopenner"
                >
                    <SocialMediaIcon src={telegram} />
                </SocialMediaLink>
                <SocialMediaLink
                    href={'https://medium.com/@unitas.foundation'}
                    target={'_blank'}
                    rel="noreferrer noopenner"
                >
                    <SocialMediaIcon src={medium} />
                </SocialMediaLink>
            </SocialMedia>
        </FooterWrapper>
    );
}

export default Footer;

const FooterWrapper = styled.div`
    padding: 32px 0;
    margin: 0 ${MOBILE_PADDING}px 25px ${MOBILE_PADDING}px;

    display: flex;
    flex-direction: column;
    @media ${device.tablet} {
        margin: 0 ${TABLET_MARGIN}px;
    }
    @media ${device.laptop} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 ${MARGIN}px;
        padding: 2.22vw 0;
    }
`;

const Description = styled.p`
    text-align: center;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 14px;
    @media ${device.laptop} {
        margin-bottom: 0;
        font-size: 0.97vw;
    }
`;

const SocialMedia = styled.div`
    display: flex;
    justify-content: center;
    order: 1;
`;
const SocialMediaLink = styled.a`
    &:not(:last-child) {
        margin-right: 12px;
    }
`;
const SocialMediaIcon = styled.img`
    width: 24px;
    height: 24px;
    @media ${device.laptop} {
        height: 1.66vw;
        width: 1.66vw;
    }
`;
