import React from 'react';
import styled from 'styled-components';

import { Trans, useTranslation } from 'react-i18next';
import { device } from '../devices';

// assets
import northEast from '../assets/images/north_east.png';

import adityaGupta from '../assets/images/aditya_gupta.png';
import andrewChen from '../assets/images/andrew_chen.png';
import mattHuang from '../assets/images/matt_huang.png';
import stephenRyan from '../assets/images/stephen_ryan.png';
import sunHuang from '../assets/images/sun_huang.png';
import titanCheng from '../assets/images/titan_cheng.png';
import wayneHuang from '../assets/images/wayne_huang.png';
import winstonHsiao from '../assets/images/winston_hsiao.png';
import jerryLi from '../assets/images/jerry_li.png';
import ethanYang from '../assets/images/ethan_yang.png';

function Teams() {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Title>
                <Trans>
                    The <br /> Unitas Team
                </Trans>
            </Title>
            <Content>
                <SubTitle>{t('Co-Founders')}</SubTitle>
                <AvatarsList>
                    <AvatarFrame
                        href={'https://resources.unitas.foundation/wayne'}
                        target={'_blank'}
                        rel="noreferrer noopenner"
                    >
                        <AvatarImg src={wayneHuang} />
                        <AvatarTitle>
                            {t('Wayne Huang')}, {t('PhD')}
                        </AvatarTitle>
                        <AvatarSubTitle>
                            {t('Co-Founder and Board Member')}
                        </AvatarSubTitle>
                        <NorthEastImg src={northEast} />
                    </AvatarFrame>
                    <AvatarFrame
                        href={'https://resources.unitas.foundation/winston'}
                        target={'_blank'}
                        rel="noreferrer noopenner"
                    >
                        <AvatarImg src={winstonHsiao} />
                        <AvatarTitle>{t('Winston Hsiao')}</AvatarTitle>
                        <AvatarSubTitle>
                            {t('Co-Founder and Board Member')}
                        </AvatarSubTitle>
                        <NorthEastImg src={northEast} />
                    </AvatarFrame>
                    <AvatarFrame
                        href={'https://resources.unitas.foundation/sun'}
                        target={'_blank'}
                        rel="noreferrer noopenner"
                    >
                        <AvatarImg src={sunHuang} />
                        <AvatarTitle>{t('Sun Huang')}</AvatarTitle>
                        <AvatarSubTitle>
                            {t(
                                'Co-Founder, Board Member and Chief Technology Officer'
                            )}
                        </AvatarSubTitle>
                        <NorthEastImg src={northEast} />
                    </AvatarFrame>
                </AvatarsList>
                <SubTitle>{t('Founding Contributors')}</SubTitle>
                <AvatarsList>
                    <AvatarFrame
                        href={'https://resources.unitas.foundation/aditya'}
                        target={'_blank'}
                        rel="noreferrer noopenner"
                    >
                        <AvatarImg src={adityaGupta} />
                        <AvatarTitle>{t('Aditya Gupta')}</AvatarTitle>
                        <AvatarSubTitle>
                            {t('Founding Contributor and Product Manager')}
                        </AvatarSubTitle>
                        <NorthEastImg src={northEast} />
                    </AvatarFrame>
                    <AvatarFrame
                        href={'https://resources.unitas.foundation/andrew'}
                        target={'_blank'}
                        rel="noreferrer noopenner"
                    >
                        <AvatarImg src={andrewChen} />
                        <AvatarTitle>
                            {t('Andrew Chen')}, {t('PhD')}
                        </AvatarTitle>
                        <AvatarSubTitle>
                            {t('Founding Contributor and DeFi Researcher')}
                        </AvatarSubTitle>
                        <NorthEastImg src={northEast} />
                    </AvatarFrame>
                    <AvatarFrame />
                </AvatarsList>
                <SubTitle>{t('Founding Members')}</SubTitle>
                <AvatarsList>
                    <AvatarFrame
                        href={
                            'https://resources.unitas.foundation/stephen-ryan'
                        }
                        target={'_blank'}
                        rel="noreferrer noopenner"
                    >
                        <AvatarImg src={stephenRyan} />
                        <AvatarTitle>{t('Stephen Ryan')}</AvatarTitle>
                        <AvatarSubTitle>{t('Founding Member')}</AvatarSubTitle>
                        <NorthEastImg src={northEast} />
                    </AvatarFrame>
                    <AvatarFrame
                        href={'https://resources.unitas.foundation/titan'}
                        target={'_blank'}
                        rel="noreferrer noopenner"
                    >
                        <AvatarImg src={titanCheng} />
                        <AvatarTitle>{t('Titan Cheng')}</AvatarTitle>
                        <AvatarSubTitle>{t('Founding Member')}</AvatarSubTitle>
                        <NorthEastImg src={northEast} />
                    </AvatarFrame>
                    <AvatarFrame
                        href={'https://resources.unitas.foundation/matt'}
                        target={'_blank'}
                        rel="noreferrer noopenner"
                    >
                        <AvatarImg src={mattHuang} />
                        <AvatarTitle>{t('Matt Huang')}</AvatarTitle>
                        <AvatarSubTitle>{t('Founding Member')}</AvatarSubTitle>
                        <NorthEastImg src={northEast} />
                    </AvatarFrame>
                    <AvatarFrame
                        href={'https://resources.unitas.foundation/jerry-li'}
                        target={'_blank'}
                        rel="noreferrer noopenner"
                    >
                        <AvatarImg src={jerryLi} />
                        <AvatarTitle>{t('Jerry Li')}</AvatarTitle>
                        <AvatarSubTitle>{t('Founding Member')}</AvatarSubTitle>
                        <NorthEastImg src={northEast} />
                    </AvatarFrame>
                    <AvatarFrame
                        href={'https://resources.unitas.foundation/ethan-yang'}
                        target={'_blank'}
                        rel="noreferrer noopenner"
                    >
                        <AvatarImg src={ethanYang} />
                        <AvatarTitle>{t('Ethan Yang')}</AvatarTitle>
                        <AvatarSubTitle>{t('Founding Member')}</AvatarSubTitle>
                        <NorthEastImg src={northEast} />
                    </AvatarFrame>
                    <AvatarFrame />
                </AvatarsList>
            </Content>
        </Wrapper>
    );
}

export default Teams;
const Wrapper = styled.div`
    margin-bottom: 150px;
    @media ${device.laptop} {
        margin-bottom: 16.6vw;
    }
`;

const Content = styled.div`
    margin-bottom: 30px;
    @media ${device.laptop} {
        margin: 0 8.2vw;
    }
`;

const Title = styled.h2`
    margin-bottom: 44px;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    @media ${device.laptop} {
        margin-bottom: 4.16vw;
        font-weight: 700;
        font-size: 5.55vw;
        line-height: 6.73vw;
    }
`;

const SubTitle = styled.h4`
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    @media ${device.laptop} {
        margin-bottom: 2.7vw;
        font-weight: 700;
        font-size: 1.94vw;
        line-height: 2.91vw;
    }
`;

const AvatarsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:not(:last-child) {
        margin-bottom: 28px;
    }
    @media ${device.laptop} {
        &:not(:last-child) {
            margin-bottom: 5.55vw;
        }
    }
`;
const AvatarFrame = styled.a`
    flex-shrink: 0;
    flex-grow: 0;
    width: 40.8vw;
    cursor: pointer;
    margin-bottom: 16px;
    @media ${device.laptop} {
        width: 19.4vw;
        margin-bottom: 2.77vw;
    }
`;
const AvatarImg = styled.img`
    margin-bottom: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 40.8vw;
    height: 50vw;
    border-radius: 16px;
    @media ${device.laptop} {
        border-radius: 1.11vw;
        margin-bottom: 1.1vw;
        width: 19.4vw;
        height: 25vw;
    }
`;
const AvatarTitle = styled.h4`
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 8px;
    color: #000000;
    @media ${device.laptop} {
        margin-bottom: 0.83vw;
        font-size: 1.94vw;
        line-height: 2.35vw;
    }
`;
const AvatarSubTitle = styled.p`
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    margin-bottom: 8px;
    @media ${device.laptop} {
        margin-bottom: 0.83vw;
        font-weight: 700;
        font-size: 1.38vw;
        line-height: 1.66vw;
    }
`;

const NorthEastImg = styled.img`
    width: 16px;
    height: 16px;
    @media ${device.laptop} {
        width: 1.66vw;
        height: 1.66vw;
    }
`;
