import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useTranslation } from 'react-i18next';

// assets
import logo from '../assets/icons/unitas-logo.png';
import northEast from '../assets/images/north_east.png';
import indicator from '../assets/images/indicator.png';
import mail from '../assets/images/mail.png';
import twitter from '../assets/images/twitter.png';
import medium from '../assets/images/medium.png';
import telegram from '../assets/images/telegram.png';
import menu from '../assets/icons/menu.png';

// constants
import { MARGIN, MOBILE_PADDING, TABLET_MARGIN } from '../constants';

// utils
import { device } from '../devices';
import LaunchAppButton from './LaunchAppButton';

import { useTouchOutside } from '../hooks/useTouchOutside';
import { useMouseDownOutside } from '../hooks/useMouseDownOutside';
import { useInView } from 'react-intersection-observer';

function Header() {
    const { t } = useTranslation();
    const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
    const hamburgerRef = useRef(null);
    const { ref, inView } = useInView({
        threshold: 1,
        root: document.querySelector('#root'),
        rootMargin: '-100px 0px 0px 0px',
    });
    const closeMenu = useCallback(() => {
        setIsMenuActive(false);
    }, [setIsMenuActive]);
    useTouchOutside(
        hamburgerRef,
        () => {
            closeMenu();
        },
        isMenuActive
    );
    useMouseDownOutside(
        hamburgerRef,
        () => {
            closeMenu();
        },
        isMenuActive
    );

    useEffect(() => {
        window.addEventListener('scroll', closeMenu);
        return () => window.removeEventListener('scroll', closeMenu);
    }, []);

    return (
        <FixWrapper ref={ref}>
            <Container>
                <HeaderContent>
                    <UnitasWrapper>
                        <UnitasLogo src={logo} alt="Unitas" />
                        <Unitas>Unitas</Unitas>
                    </UnitasWrapper>

                    <LaunchAppButtonWrapper>
                        <LaunchAppButton
                            size={'Small'}
                            isTooltipFloating={false}
                        />
                    </LaunchAppButtonWrapper>
                    <Hamburger
                        ref={hamburgerRef}
                        onClick={() => {
                            setIsMenuActive(!isMenuActive);
                        }}
                    >
                        <Nav
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={isMenuActive ? 'active' : ''}
                        >
                            <NavUnorderList>
                                <NavUnorderListItem>
                                    ABOUT
                                    <IndicatorImg src={indicator} />
                                </NavUnorderListItem>
                                <NavUnorderListItem>
                                    <NavItemAnchor
                                        href={
                                            'https://resources.unitas.foundation/whitepapers'
                                        }
                                        target={'_blank'}
                                        rel="noreferrer noopenner"
                                    >
                                        {t('WHITEPAPERS')}
                                        <NorthEastImg src={northEast} />
                                    </NavItemAnchor>
                                </NavUnorderListItem>
                                <NavUnorderListItem>
                                    <NavItemAnchor
                                        href={
                                            'https://resources.unitas.foundation/unitas-dashboard'
                                        }
                                        target={'_blank'}
                                        rel="noreferrer noopenner"
                                    >
                                        {t('DASHBOARD')}
                                        <NorthEastImg src={northEast} />
                                    </NavItemAnchor>
                                </NavUnorderListItem>
                                <NavUnorderListItem>
                                    <NavItemAnchor
                                        href={'https://wiki.unitas.foundation/unitas-protocol-v1/audit-reports'}
                                        target={'_blank'}
                                        rel="noreferrer noopenner"
                                    >
                                        {t('AUDIT')}
                                        <NorthEastImg src={northEast} />
                                    </NavItemAnchor>
                                </NavUnorderListItem>
                                <NavUnorderListItem>
                                    <NavItemAnchor
                                        href={'https://wiki.unitas.foundation'}
                                        target={'_blank'}
                                        rel="noreferrer noopenner"
                                    >
                                        {t('WIKI')}
                                        <NorthEastImg src={northEast} />
                                    </NavItemAnchor>
                                </NavUnorderListItem>
                                <NavUnorderListItem>
                                    <NavItemAnchor
                                        href="https://resources.unitas.foundation/blog"
                                        target={'_blank'}
                                        rel="noreferrer noopenner"
                                    >
                                        {t('BLOG')}
                                        <NorthEastImg src={northEast} />
                                    </NavItemAnchor>
                                </NavUnorderListItem>
                                <Divider></Divider>

                                <SocialMedia>
                                    <SocialMediaItem
                                        href={'mailto:team@unitas.foundation'}
                                        target={'_blank'}
                                        rel="noreferrer noopenner"
                                    >
                                        <SocialMediaImage
                                            width={24}
                                            height={24}
                                            src={mail}
                                        />
                                    </SocialMediaItem>
                                    <SocialMediaItem
                                        href={
                                            'https://twitter.com/UnitasProtocol'
                                        }
                                        target={'_blank'}
                                        rel="noreferrer noopenner"
                                    >
                                        <SocialMediaImage
                                            width={24}
                                            height={24}
                                            src={twitter}
                                        />
                                    </SocialMediaItem>
                                    <SocialMediaItem
                                        href={
                                            'https://resources.unitas.foundation/telegram'
                                        }
                                        target={'_blank'}
                                        rel="noreferrer noopenner"
                                    >
                                        <SocialMediaImage
                                            width={24}
                                            height={24}
                                            src={telegram}
                                        />
                                    </SocialMediaItem>
                                    <SocialMediaItem
                                        href={
                                            'https://medium.com/@unitas.foundation'
                                        }
                                        target={'_blank'}
                                        rel="noreferrer noopenner"
                                    >
                                        <SocialMediaImage
                                            width={24}
                                            height={24}
                                            src={medium}
                                        />
                                    </SocialMediaItem>
                                </SocialMedia>
                            </NavUnorderList>
                        </Nav>

                        <HamburgerImage width={24} height={24} src={menu} />
                    </Hamburger>
                </HeaderContent>
                <Border isScrollOver={inView} />
            </Container>
        </FixWrapper>
    );
}

export default Header;
const FixWrapper = styled.div`
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: #eeeeee;
`;
const Container = styled.div`
    position: relative;
    margin: 0 ${MOBILE_PADDING}px;
    padding: 16px 0 16px 0;

    width: 100%;
    display: flex;
    box-sizing: border-box;

    align-items: center;
    justify-content: space-between;
    @media ${device.tablet} {
        margin: 0 ${TABLET_MARGIN}px;
    }

    @media ${device.laptop} {
        padding: 26px 0;
        margin: 0 ${MARGIN}px;
    }
`;
const Border = styled.div<{ isScrollOver: boolean }>`
    background-color: #28292b;
    position: absolute;
    bottom: 0;
    left: -${MOBILE_PADDING}px;
    height: 0.5px;
    width: calc(100% + ${MOBILE_PADDING * 2}px);
    transition: opacity 0.2s;
    opacity: 0;
    z-index: -1;
    ${(props) =>
        props.isScrollOver
            ? css`
                  opacity: 1;
              `
            : ''}
    @media ${device.tablet} {
        left: -${TABLET_MARGIN}px;
        width: calc(100% + ${TABLET_MARGIN * 2}px);
    }

    @media ${device.laptop} {
        width: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
`;

const HeaderContent = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: relative;
`;
const Nav = styled.nav`
    position: absolute;
    right: 0;
    top: 45px;
    transform: scale(0);
    transition: all 0.2s ease;
    transform-origin: top center;
    visibility: hidden;
    opacity: 0;
    &.active {
        visibility: visible;
        transform: scale(1);
        opacity: 1;
    }
    @media ${device.laptop} {
        position: relative;
        top: 0px;
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }
`;
const NavUnorderList = styled.ul`
    border: 1px solid ${(props) => props.theme.colors.black};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.white};
    padding: 24px 32px;
    cursor: default;
    @media ${device.laptop} {
        align-items: center;
        background-color: #eeeeee;
        flex-direction: row;
        padding: 0;
        border: none;
        border-radius: 0px;
    }
`;
const NavUnorderListItem = styled.li`
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    @media ${device.laptop} {
        margin-bottom: 0;
        font-size: 1.1vw;
        &:not(:last-child) {
            margin-right: 32px;
        }
    }
`;
const NavItemAnchor = styled.a`
    display: flex;
    font-size: 16px;
    font-weight: 400;
    @media ${device.laptop} {
        font-size: 1.1vw;
        align-items: center;
    }
`;

const NorthEastImg = styled.img`
    width: 16px;
    height: 16px;
    margin-left: 4px;
    @media ${device.laptop} {
        width: 1.1vw;
        height: 1.1vw;
        margin-left: 0.27vw;
    }
`;

const IndicatorImg = styled.img`
    width: 10px;
    height: 8px;
    position: absolute;
    left: -16px;
    transform: rotate(90deg);
    @media ${device.laptop} {
        margin: 0 auto;
        bottom: -0.83vw;
        width: 0.69vw;
        height: 0.55vw;
        left: 0;
        right: 0;
        transform: rotate(0);
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.black};
    margin-bottom: 24px;
    @media ${device.laptop} {
        display: none;
    }
`;

const SocialMedia = styled.div`
    display: flex;
    justify-content: center;
    @media ${device.laptop} {
        display: none;
    }
`;
const SocialMediaItem = styled.a`
    cursor: pointer;
    &:not(:last-child) {
        margin-right: 16px;
    }
`;
const SocialMediaImage = styled.img``;

const LaunchAppButtonWrapper = styled.div`
    display: none;
    @media ${device.laptop} {
        display: block;
        order: 1;
    }
`;
const Hamburger = styled.div`
    cursor: pointer;
`;
const HamburgerImage = styled.img`
    @media ${device.laptop} {
        display: none;
    }
`;
const UnitasWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const UnitasLogo = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 6px;
    @media ${device.laptop} {
        margin-right: 8px;
        width: 2.2vw;
        height: 2.2vw;
    }
`;
const Unitas = styled.h2`
    font-weight: 800;
    font-size: 16px;
    @media ${device.laptop} {
        font-size: 1.66vw;
    }
`;
