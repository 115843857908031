const gradients = {
    blue_0: ['#81C1FE', '#0184FF'],
    blue_2: ['#A0D1FF', '#329CFF'],
    blue_3: ['#17102D', '#19173A', '#152145', '#0A243D', '#021520', '#010101'],
};

const orange = {
    /** F0CDB9 */
    orange_1: '#F0CDB9',
    /** F4AE91 */
    orange_2: '#F4AE91',
    /** F28456 */
    orange_3: '#F28456',
    /** C8501E */
    orange_4: '#C8501E',
    /** 9B4420 */
    orange_5: '#9B4420',
};

const blue = {
    /** 81C1FE */
    blue_1: '#81C1FE',
    /** 3EA1FF */
    blue_2: '#3EA1FF',
    /** 0184FF */
    blue_3: '#0184FF',
    /** 0564BE */
    blue_4: '#0564BE',
    /** 04427E */
    blue_5: '#04427E',
    /** 413571 */
    blue_6: '#413571',
    /** 293F87 */
    blue_7: '#293F87',
};

const dark_blue = {
    /** B7C1DA */
    dark_blue_1: '#B7C1DA',
    /** 7688BA */
    dark_blue_2: '#7688BA',
    /** 4C64A4 */
    dark_blue_3: '#4C64A4',
    /** 3B4E80 */
    dark_blue_4: '#3B4E80',
    /** 2A3D6E */
    dark_blue_5: '#2A3D6E',
};
const yellow = {
    /** F8EBBC */
    yellow_1: '#F8EBBC',
    /** F8E18F */
    yellow_2: '#F8E18F',
    /** FAD75A */
    yellow_3: '#FAD75A',
    /** E6B612 */
    yellow_4: '#E6B612',
    /** BD950D */
    yellow_5: '#BD950D',
};

const purple = {
    /** D4B9DA */
    purple_1: '#D4B9DA',
    /** BC8FC7 */
    purple_2: '#BC8FC7',
    /** AD74BC */
    purple_3: '#AD74BC',
    /** 7E478C */
    purple_4: '#7E478C',
    /** 6A3D76 */
    purple_5: '#6A3D76',
};

const cyan = {
    /** AFD1D4 */
    cyan_1: '#AFD1D4',
    /** 89CCD2 */
    cyan_2: '#89CCD2',
    /** 69B4BB */
    cyan_3: '#69B4BB',
    /** 408C93 */
    cyan_4: '#408C93',
    /** 346469 */
    cyan_5: '#346469',
};

const red = {
    /** EBA7A0 */
    red_1: '#EBA7A0',
    /** DD7368 */
    red_2: '#DD7368',
    /** DD4C3D */
    red_3: '#DD4C3D',
    /** B23529 */
    red_4: '#B23529',
    /** 822519 */
    red_5: '#822519',
};
const green = {
    /** BAE3CC */
    green_1: '#BAE3CC',
    /** 90D9B1 */
    green_2: '#90D9B1',
    /** 56C488 */
    green_3: '#56C488',
    /** 2E8153 */
    green_4: '#2E8153',
    /** 1B5234 */
    green_5: '#1B5234',
};
const grey = {
    /** A3A3A3 */
    grey_0: '#A3A3A3',
    /** 7C7C7C */
    grey_1: '#7C7C7C',
    /** 474F65 */
    grey_2: '#474F65',
    /** 3F464C */
    grey_3: '#3F464C',
    /** 3C4248 */
    grey_4: '#3C4248',
    /** 313748 */
    grey_5: '#313748',
    /** 2A2E34 */
    grey_6: '#2A2E34',
    /** 252627 */
    grey_7: '#252627',
    /** 202326 */
    grey_8: '#202326',
    /** 15161A */
    grey_9: '#15161A',
    /** 0D0E0F */
    grey_10: '#0D0E0F',
};

const solids = {
    white: '#FFFFFF',
    black: '#000000',
    /** 000000CC */
    shadow: '#000000CC',

    ...orange,
    ...blue,
    ...yellow,
    ...dark_blue,
    ...purple,
    ...cyan,
    ...red,
    ...green,
    ...grey,
    dark_purple: '#242B3E',
    old_blue_0: '#01D1E2',
    old_blue_1: '#01CDFF',
    old_purple_0: '#8F6AFF', // key under review
};

export const colors = {
    ...solids,
    gd: gradients,
};
