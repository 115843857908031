import React from 'react';
import styled from 'styled-components';
import { MARGIN, TABLET_MARGIN } from '../constants';
import { device } from '../devices';

function HorizontalPadder(props: { children: JSX.Element }) {
    return (
        <Wrapper>
            <Content>{props.children}</Content>
        </Wrapper>
    );
}
export default HorizontalPadder;
export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const Content = styled.div`
    padding: 0 24px;
    width: 100%;
    box-sizing: border-box;
    @media ${device.tablet} {
        padding: 0;
        margin: 0 ${TABLET_MARGIN}px;
    }
    @media ${device.laptop} {
        margin: 0 ${MARGIN}px;
    }
`;
